import React from "react";
import { Box, Container, Stack, Typography, Grid } from "@mui/material";

import { Button } from "@/commons/button/Button";
import { colors } from "@/theme";
import MilestoneIcon from "@/assets/imgs/offering/milestone.svg";
import TeamExtensionIcon from "@/assets/imgs/offering/teamExtension.svg";
import ProtocolIcon from "@/assets/imgs/offering/protocol.svg";
import ContractIcon from "@/assets/imgs/offering/contract.svg";
import GameOfLifeAnimation from "@/commons/gameOfLifeAnimation/GameOfLifeAnimation";

const benefits = [
  {
    slug: "milestone",
    color: colors.yellow,
    icon: MilestoneIcon,
    title: "Milestone Based",
    description:
      "Whether your organization is crypto-native or just exploring the space, our experts can help flesh out your project requirements and deliver a production-ready solution. You own the code, we train your team to maintain it. ",
  },
  {
    slug: "team-extension",
    color: colors.violet,
    icon: TeamExtensionIcon,
    title: "Team Extensions",
    description:
      "Embed engineers into your core team to accelerate your web3 development capabilities and roadmap. Plug and play with our developers as your organization needs evolve. Reduce bureaucracy to a single onboarding contract and a monthly invoice. ",
  },
  {
    slug: "protocol-partnerships",
    color: colors.magenta,
    icon: ProtocolIcon,
    title: "Protocol Partnerships",
    description:
      "Ready to start scaling your ecosystem? We’ll assemble a specialized team that works autonomously to advance roadmap items, side MVPs, battle-test your developer tools, and support 3rd parties looking to integrate your tech ",
  },
  {
    slug: "contract-to-hire",
    color: colors.blue,
    icon: ContractIcon,
    title: "Contract-to-Hire",
    description:
      "Leverage our pool of top-experienced web3 devs and reduce the hiring process to a 24-72h team assembly. Work and test our developers for some months before hiring them directly to your organization.",
  },
];

export const OfferingSection: React.FC = () => {
  return (
    <Box mt={[16, 24, 32]} position='relative'>
      <GameOfLifeAnimation fadeFromBlack className='benefits' />
      <Container maxWidth='lg' sx={{ position: "relative" }}>
        <Typography
          variant='h6'
          component='h2'
          textTransform='uppercase'
          textAlign='center'
          letterSpacing={5}>
          Our Offering{" "}
          <span role='img' aria-label='magic wand'>
            💼
          </span>
        </Typography>
        <Grid container spacing={5} alignItems='stretch' mt={8}>
          {benefits.map((benefit) => {
            return (
              <Grid item key={benefit.slug} xs={12} sm={6} lg={6}>
                <Stack
                  px={3}
                  py={8}
                  direction='column'
                  spacing={3}
                  sx={{
                    bgcolor: "rgba(0,0,0,0.8)",
                    border: `4px solid ${benefit.color}`,
                    borderRadius: "24px",
                    height: "100%",
                    "&:hover": {
                      background: `linear-gradient(rgba(0,0,0,0.8) 40%, ${benefit.color})`,
                    },
                  }}>
                  <img
                    src={benefit.icon}
                    alt={benefit.slug}
                    loading='lazy'
                    height={50}
                    width='auto'
                  />
                  <Typography variant='h6' component='h3' textAlign='center'>
                    {benefit.title}
                  </Typography>
                  <Typography
                    variant='body1'
                    lineHeight={1.5}
                    textAlign='center'>
                    {benefit.description}
                  </Typography>
                  <script type='application/ld+json'>
                    {JSON.stringify({
                      "@context": "https://schema.org/",
                      "@type": "Service",
                      serviceType: "dOrg service offer",
                      provider: {
                        "@type": "Organization",
                        name: "dOrg",
                      },
                      hasOfferCatalog: {
                        "@type": "OfferCatalog",
                        name: benefit.title,
                        itemListElement: [
                          {
                            "@type": "OfferCatalog",
                            name: benefit.slug,
                            itemListElement: [
                              {
                                "@type": "Offer",
                                itemOffered: {
                                  "@type": "Service",
                                  description: benefit.description,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    })}
                  </script>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
        <Grid container justifyContent='center' mt={12}>
          <Grid item>
            <Button href='/#/hire' variant='outlined'>
              Custom solution? Let's talk
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
