import React, { Fragment } from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import { colors } from "../../theme";
import FaqItem, { FaqItemProps } from "./components/FaqItem/FaqItem";

export const FaqsView: React.FC = () => {
  return (
    <Container maxWidth='lg'>
      <Grid container spacing={9} mb={24}>
        <Grid item>
          <Grid
            container
            spacing={3}
            sx={{
              display: "flex",
              marginTop: "10vh",
              transform: "translateY(5vh)",
            }}>
            <Grid item>
              <Typography variant='h1' sx={{ fontSize: ["3rem", "4rem"] }}>
                Frequently Asked Questions
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: 20, maxWidth: 550 }}>
                Have a question that isn&apos;t here?{" "}
                <Link href={"mailto:contact@dorg.tech"} target='_blank'>
                  <span style={{ color: colors.blue }}>Drop us a line.</span>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={7} flexDirection='column'>
            {FAQS.map((item, index) => (
              <Grid item key={index}>
                <FaqItem
                  titleColor={item.titleColor}
                  sectionTitle={item.sectionTitle}
                  faqs={item.faqs}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const FAQS: FaqItemProps[] = [
  {
    sectionTitle: "On dOrg",
    titleColor: colors.purple,
    faqs: [
      {
        question: "What is dOrg?",
        answer: (
          <Fragment>
            Is a collective of +100 top web3 developers offering Smart Contract
            development, Web3 Integrations, AI x Web3, Mechanism Design,
            Full-Stack Engineering, UX/UI services, etc.
            <br />
            <br />
            So far we’ve worked with +100 web3 projects generating +$5B of total
            LTV in shipped products as well as +11$M on-chain revenue. Some of
            our clients are: Gnosis, Starkware, EthFoundation, Usual, Tezos,
            Balancer, The Graph, etc.
            <br />
            <br />
            We operate ourselves as a cooperative/DAO since 2019.
          </Fragment>
        ),
      },
      {
        question: "How does dOrg work as an organization?",
        answer: (
          <Fragment>
            Our structure and processes are open to the community to fork from
            our best practices. Check out our{" "}
            <Link
              href='https://docs.dorg.tech/'
              target='_blank'
              sx={{ color: colors.purple }}>
              Handbook!
            </Link>
          </Fragment>
        ),
      },
    ],
  },
  {
    sectionTitle: "On working with dOrg",
    titleColor: colors.green,
    faqs: [
      {
        question: "What services does dOrg offer?",
        answer: (
          <Fragment>
            Engineering Services:
            <br />
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Smart Contracts Development:
              </strong>{" "}
              We develop secure, robust and optimized smart contracts for
              different networks. Our devs are experts in Solidity, Rust and
              Golang.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>Web3 Integrations:</strong>{" "}
              We leverage our mastery on JavaScript skills to enable seamless
              integrations with blockchain networks to deliver great UX.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>
                AI x Web3 Development:
              </strong>{" "}
              Merge AI with decentralized web tech. Leverage machine learning,
              smart contracts, and blockchain integrations for groundbreaking
              applications.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Full-Stack Engineering:
              </strong>{" "}
              Our proficiency in HTML, Sass, JavaScript, Python, and beyond,
              will help you build complete web systems.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>DevOps:</strong> We
              seamlessly orchestrate infrastructure and deployment pipelines for
              streamlined, efficient Web2 operations.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>UX/UI Design:</strong> We
              craft collaborative, user-centered interfaces ensuring intuitive
              digital experiences on Web3.
            </li>
            <br />
            <br />
            Mechanism Design Services:
            <br />
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>Tokenomics:</strong> Unlock
              your project economic framework, from supply dynamics, incentives
              to distribution strategies.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Governance Mechanisms:
              </strong>{" "}
              Defining robust voting power strategies and decentralized
              decision-making processes to empower your project's future.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>
                DAO Set Up & Tooling:
              </strong>{" "}
              Pioneering DAOs with end-to-end set-up and cutting-edge DAO
              tooling development to craft your DAO.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Game Theory Design:
              </strong>{" "}
              We design strategic interactions, incentives and rational behavior
              analysis for your project.
            </li>
          </Fragment>
        ),
      },
      {
        question: "What are our service packages?",
        answer: (
          <Fragment>
            <li>
              <strong style={{ fontWeight: "bold" }}>Milestone Based:</strong>{" "}
              Whether your organization is crypto-native or just exploring the
              space, our experts can help flesh out your project requirements
              and deliver a production-ready solution. You own the code and we
              train your team to maintain it.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>Team Extension:</strong> We
              embed engineers with your core team to augment and accelerate
              their web3 development capabilities. We’ll help transition the
              project to your team when the time is right.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Protocol Partnership:
              </strong>{" "}
              Ready to start scaling your developer ecosystem? We’ll assemble a
              specialized team that works autonomously to advance roadmap items,
              battle-test your developer tools, and support 3rd parties looking
              to integrate your tech.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>Contract-to-Hire:</strong>{" "}
              Leverage our pool of top-experienced web3 devs and reduce the
              hiring process to a 24-72h team assembly. Work and test our
              developers for some months before hiring them directly to your
              organization.
            </li>
            <br />
            <br />
            We also offer packages such as:
            <br />
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>Scoping Sprint:</strong> If
              you do not have technical specifications decided, be prepared for
              a small project scope with us first, and afterwards a bigger
              estimate on your project build.
            </li>
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Specific Consultations:
              </strong>{" "}
              Tokenomics, Smart Contract Architecture, Protocol Development,
              Governance, etc.
            </li>
          </Fragment>
        ),
      },
      {
        question: "Can I check work done by dOrg?",
        answer: (
          <Fragment>
            Absolutely! We&apos;ve created{" "}
            <Link
              href='https://www.dorg.tech/#/case-studies'
              target='_blank'
              sx={{ color: colors.green }}>
              detailed case studies
            </Link>{" "}
            of the work we did for StarkWare, Gnosis Guild, Tezos and DoinGud.
            If you have any questions feel free to message us on Discord or shot
            us an email at{" "}
            <Link href={"mailto:contact@dorg.tech"} target='_blank'>
              <span style={{ color: colors.green }}>
                {" "}
                contact@dorg.tech {";)"}
              </span>
            </Link>
          </Fragment>
        ),
      },
      {
        question: "What are the benefits of working with dOrg?",
        answer: (
          <Fragment>
            Working with dOrg is a unique, collaborative, and efficient
            experience tailored to meet our clients' needs. You can expect:
            <br />
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Access to a +100 Top-end Senior Dev Pool.
              </strong>{" "}
              Since 2019, dOrg has onboarded +80 top-end senior developers with
              diverse expertise in the web3 development field. This allows us to
              assemble highly skilled teams for any client project.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Fast 24-72h Team Assembly.
              </strong>{" "}
              Our DAO’s pool of +80 top-tier senior devs enables us to build
              custom teams within 24-72 hours, compared to the +35 days typical
              hiring process. Clients can refine their ideal team composition
              before starting or even adjust it during the engagement.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Dedicated Quality Assurance.
              </strong>{" "}
              Every client project is assigned a Tech Lead Developer who ensures
              code quality, rigorous testing, and adherence to high standards,
              including manual, automated, performance, and security testing
              before auditing.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Plug and Play setup with your Organization.
              </strong>{" "}
              Our teams integrate seamlessly with your organization, whether as
              an extension of your in-house team, a milestone-based project, or
              research and consulting to refine strategic development.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Reduce Hiring Bureaucracy to a Single Contract.
              </strong>{" "}
              dOrg removes the burden of contract management, tax
              responsibilities, and compliance issues. We handle all
              administrative aspects so you can focus on what truly matters.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Reduce Workload to Managing and Senior Tech Positions.
              </strong>{" "}
              Partnering with dOrg eliminates the need for hiring, training, and
              retaining in-house developers. This saves time, money, and reduces
              workload, allowing senior tech leaders to focus on critical
              business needs.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Flexible Team Customization.
              </strong>{" "}
              We offer the ability to scale teams up or down based on project
              needs without the friction of hiring, training, or firing,
              ensuring resource efficiency and adaptability.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Reduce Payment Complexity to One Invoice/Month.
              </strong>{" "}
              Receive a single monthly invoice (or bi-weekly if preferred)
              covering the entire project team, simplifying payments and expense
              tracking.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Ability to Hire Directly After Collaboration on a
                Contract-to-Hire Basis.
              </strong>{" "}
              Clients can transition from working with dOrg to directly hiring
              team members, ensuring continuity and seamless onboarding.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Ongoing Development, Support, and Maintenance.
              </strong>{" "}
              We offer long-term partnerships, extending protocol development,
              improving web3 applications, and integrating new Web3 tools (Smart
              Contracts, Integrations, ML). Our expertise has supported projects
              like Gnosis (Safe), Tezos, and Starkware since 2021.
            </li>
            <br />
            <li>
              <strong style={{ fontWeight: "bold" }}>
                Post-project Support.
              </strong>{" "}
              Even after project completion, we provide ongoing support and
              maintenance, ensuring your product remains up-to-date and
              functional. We are committed to long-term relationships and are
              always available for future projects and updates.
            </li>
          </Fragment>
        ),
      },
      {
        question: "What's the process of hiring dOrg?",
        answer: (
          <Fragment>
            When you choose to work with our DAO work collective:
            <br />
            <br />
            <li>
              First, make sure we know more about your specific needs by filling
              out this{" "}
              <Link
                href='https://www.dorg.tech/#/hire'
                target='_blank'
                sx={{ color: colors.green }}>
                form.
              </Link>{" "}
            </li>
            <br />
            <li>
              If we have a dedicated team available for you, we&apos;ll reach
              you out to schedule a discovery call to define how we can best
              help you and ensure we&apos;re aligned with your vision. This
              process helps us define the project scope and select the
              best-suited team members.
              <br />
              Hint: If you do not have technical specifications decided, be
              prepared for a small project scope with us first, and afterwards a
              bigger estimate on your project build.
            </li>
            <br />
            <li>
              We&apos;ll come up with a tailored collaboration statement of work
              after our initial meeting based on your specific needs. Bear in
              mind that you can utilize your project&apos;s tokens to align
              incentives with our builders in addition to regular stablecoins.
            </li>
            <br />
            <li>
              Once we sign the statement of work we&apos;ll set up a kickoff
              call and officially introduce you (if we haven&apos;t already) to
              the team: Devs, Tech Lead and PM.
            </li>
            <br />
            <li>
              Right after we receive the upfront payment from the invoice and
              have attended our kickoff call, the team will get right to work!
            </li>
            <br />
            <li>
              Expect transparent communication, adaptive collaboration,
              cutting-edge expertise, decentralized project management, quality
              assurance and post-project support while working with dOrg. Read
              more about our best practices on “What does working with dOrg look
              like?”.
            </li>
            <br />
            By working with dOrg, you can be confident that your project will be
            executed with the utmost professionalism, efficiency, and expertise.
            Our dedicated teams are ready to help you bring your vision to life!
          </Fragment>
        ),
      },
      {
        question: "We want to work with dOrg, what's the first step?",
        answer: (
          <Fragment>
            Let us know about your needs by filling up this{" "}
            <Link
              href='https://www.dorg.tech/#/hire'
              target='_blank'
              sx={{ color: colors.green }}>
              form
            </Link>{" "}
            If we have a dedicated team available for you, we&apos;ll reach you
            out to schedule a call to define how we can help you. Be prepared
            for a small project to scope first and then a bigger estimate on
            your project build.
          </Fragment>
        ),
      },
    ],
  },
  {
    sectionTitle: "On joining dOrg",
    titleColor: colors.magenta,
    faqs: [
      {
        question: "How many members does dOrg have?",
        answer: (
          <Fragment>
            dOrg started with 4 members on 2019. Currently it&apos;s a community
            of 100-ish members distributed around the world from +20
            nationalities. Around 65-ish members are active contributors to the
            organization either working on client-facing projects or internal
            Ops.
          </Fragment>
        ),
      },
      {
        question: "How do I apply to join dOrg?",
        answer: (
          <Fragment>
            Fill up this{" "}
            <Link
              href='https://www.dorg.tech/#/join'
              target='_blank'
              sx={{ color: colors.magenta }}>
              form
            </Link>{" "}
            if you want to join us! Please notice that we&apos;ll reach you out
            to start the interview process if we have matching positions
            available inside the organization.
          </Fragment>
        ),
      },
      {
        question: "Can I join dOrg if I'm not a web3 developer? ",
        answer: (
          <Fragment>
            Sure, as long as we have an opportunity for you! dOrg is 90% formed
            by web3 developers but we also have a team of web3 specialists with
            backgrounds like economics, finances, business development,
            journalism, psychology, politics, anthropology, architecture, game
            theory, etc.
          </Fragment>
        ),
      },
    ],
  },
  {
    sectionTitle: "On dOrg Hot Seat",
    titleColor: colors.orange,
    faqs: [
      {
        question: "What's dOrg Hot Seat? ",
        answer: (
          <Fragment>
            This is our podcast! We collectively interview web3 tech leads and
            founders to discuss high technical aspects of blockchain projects.
            This is a podcast build by and for web3 developers and tech
            enthusiasts like us! Check out the episodes on{" "}
            <Link
              href='https://www.youtube.com/playlist?list=PL6f1W8NPPUfhpZFyqa8PEKAKAuJqCuZco'
              target='_blank'
              sx={{ color: colors.orange }}>
              Youtube
            </Link>{" "}
            or{" "}
            <Link
              href='https://open.spotify.com/show/5EMDtpMbqO5x8h3APoJF1C'
              target='_blank'
              sx={{ color: colors.orange }}>
              Spotify
            </Link>
            !<br />
            So far we&apos;ve had awesome guests like {""}
            <Link
              href='https://www.youtube.com/watch?v=JA7ZdYOQI-4'
              target='_blank'
              sx={{ color: colors.orange }}>
              Lens
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=itIaPLOn6Ew&t=1s'
              target='_blank'
              sx={{ color: colors.orange }}>
              Sismo
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=_bajTdUIf-A'
              target='_blank'
              sx={{ color: colors.orange }}>
              Vocdoni
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=y5JlYSlAgOg'
              target='_blank'
              sx={{ color: colors.orange }}>
              Utopia
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=rPgq7pxw6aU'
              target='_blank'
              sx={{ color: colors.orange }}>
              Kleros
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=RV9ZwvuASYQ'
              target='_blank'
              sx={{ color: colors.orange }}>
              BNB chain
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=RV9ZwvuASYQ'
              target='_blank'
              sx={{ color: colors.orange }}>
              ConsenSys
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=RV9ZwvuASYQ'
              target='_blank'
              sx={{ color: colors.orange }}>
              1Inch
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=XMHacGigGkw&list=PL6f1W8NPPUfhpZFyqa8PEKAKAuJqCuZco&index=12&pp=iAQB'
              target='_blank'
              sx={{ color: colors.orange }}>
              Safe
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=6X7VwiFxNKc&list=PL6f1W8NPPUfhpZFyqa8PEKAKAuJqCuZco&index=14&pp=iAQB'
              target='_blank'
              sx={{ color: colors.orange }}>
              Snapshot
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=19OVFg4GGHs&t=1s'
              target='_blank'
              sx={{ color: colors.orange }}>
              Superfluid
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=9_lDSJXRyHI&t=2s'
              target='_blank'
              sx={{ color: colors.orange }}>
              Polywrap
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=qpPbLZrjhfc&t=1s'
              target='_blank'
              sx={{ color: colors.orange }}>
              Bloom Network
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=q1voqfj1GUo'
              target='_blank'
              sx={{ color: colors.orange }}>
              Auth
            </Link>{" "}
            <Link
              href='https://www.youtube.com/watch?v=Wr6JX3lEL-Y'
              target='_blank'
              sx={{ color: colors.orange }}>
              Ownco
            </Link>
            , etc.
          </Fragment>
        ),
      },
      {
        question: "How is dOrg Hot Seat funded? ",
        answer: (
          <Fragment>
            dOrg funded first season of Hot Seat. Currently we are looking for
            sponsors. If that could be you, fill out this{" "}
            <Link
              href='https://airtable.com/shr4VGh3iZCIVhCg3'
              target='_blank'
              sx={{ color: colors.orange }}>
              short form
            </Link>{" "}
            and we&apos;ll reach out to you!
          </Fragment>
        ),
      },
    ],
  },
  {
    sectionTitle: "Other",
    titleColor: colors.blue,
    faqs: [
      {
        question:
          "I would like to interview a dOrg member for a press/research purposes!",
        answer: (
          <Fragment>
            Thanks for your interest in dOrg! Please it would be awesome if you
            could share a bit about your research/press interest, yourself and
            the institution you are representing. You could either send us an
            email at{" "}
            <Link href={"mailto:contact@dorg.tech"} target='_blank'>
              <span style={{ color: colors.blue }}>contact@dorg.tech</span>
            </Link>{" "}
            or text us on our{" "}
            <Link
              href='https://discord.gg/6n6mj2gjyG'
              target='_blank'
              sx={{ color: colors.purple }}>
              Discord
            </Link>{" "}
            public channels.
            <br />
            Also, it might be useful to check out our{" "}
            <Link
              href='https://docs.dorg.tech/'
              target='_blank'
              sx={{ color: colors.purple }}>
              Handbook
            </Link>{" "}
            to familiarize yourself with dOrg processes and structures!
          </Fragment>
        ),
      },
      {
        question: "I would like to discuss a potential partnership with dOrg!",
        answer: (
          <Fragment>
            Awesome, we&apos;re always up to win-win collabs! Send us an email
            at{" "}
            <Link href={"mailto:contact@dorg.tech"} target='_blank'>
              <span style={{ color: colors.blue }}>contact@dorg.tech</span>
            </Link>{" "}
            or text us on our{" "}
            <Link
              href='https://discord.gg/6n6mj2gjyG'
              target='_blank'
              sx={{ color: colors.blue }}>
              Discord
            </Link>{" "}
            what you have in mind.
          </Fragment>
        ),
      },
      {
        question: "How can I meet dOrg builders IRL?",
        answer: (
          <Fragment>
            When we are not programming or learning about the newest code
            trends, you can find us attending at IRL hackatons or web3 events
            around the world. Look for the{" "}
            <Link
              href='https://twitter.com/dOrg_tech/status/1629956395192799235'
              target='_blank'
              sx={{ color: colors.blue }}>
              dOrg t-shirt
            </Link>{" "}
            and say hi! —You can also shoot us a message on{" "}
            <Link
              href='https://discord.gg/6n6mj2gjyG'
              target='_blank'
              sx={{ color: colors.blue }}>
              Discord
            </Link>{" "}
            or
            <Link
              href='https://twitter.com/dOrg_tech'
              target='_blank'
              sx={{ color: colors.blue }}>
              Twitter
            </Link>
            ! {";)"}
          </Fragment>
        ),
      },
    ],
  },
];
