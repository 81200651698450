import { Box, Container, Typography } from "@mui/material";
import React from "react";

import BuildersMap from "../../../../commons/map/BuildersMap";

export const MapBuildersSection: React.FC = () => {
  return (
    <Box mt={[16, 24, 32]} position='relative'>
      <Container maxWidth='lg' sx={{ position: "relative", marginTop: "64px" }}>
        <Typography
          variant='h6'
          component='h2'
          textTransform='uppercase'
          textAlign='center'
          letterSpacing={5}>
          WHERE OUR BUILDERS ARE FROM
          <span role='img' aria-label='company building'>
            🌎
          </span>
        </Typography>
        <Box mt={12}>
          <Box>
            <BuildersMap />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
